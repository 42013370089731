import {
  ErrorBoundary,
  FullHeightContainer,
  IDialogProps,
  NoMatch,
  ProgressDialog,
  SvgIconRegistry,
} from "cadius-components";
import React from "react";
import { connect } from "react-redux";
import {
  HashRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";

import { resetInitialState } from "../actions/app";
import { CadiusDispatch } from "../actions/interfaces";
import {
  enterAlignModelSection,
  enterFlattenModelSection,
  enterRemeshModelSection,
} from "../actions/routes";
import { IApplicationState } from "../reducers/interfaces";
import { AppRoutes } from "../routes";
import { AlignModelRoute } from "./AlignModelRoute";
import { FlattenModelRoute } from "./FlattenModelRoute";
import { OpenModelRoute } from "./OpenModelRoute";
import { RemeshModelRoute } from "./RemeshModelRoute";

interface IState {
  resetErrorBoundary: boolean;
}

interface IProps {
  feedback: IDialogProps;
  currentProjectID?: string;

  enterAlignModelSection: () => void;
  enterFlattenModelSection: () => void;
  enterRemeshModelSection: () => void;
  resetInitialState: () => void;
}

/**
 * Root component for the application.
 *
 * This component contains the routing logic to navigate the app, and some
 * global feedback to notify the user that a given task failed/succeeded, or
 * that UI-blocking tasks are being performed.
 */
class AppImpl extends React.Component<IProps, IState> {
  private _onHashChangeEvent: (evt: HashChangeEvent) => void;

  constructor(props: IProps) {
    super(props);
    this.state = { resetErrorBoundary: false };

    // the following listener will be added at mount time (and removed at unmount time)
    // to allow the `ErrorBoundary` component to reset its internal state and this app
    // to react to a new URL put in the address bar by the user (pointing to another project)
    // it is necessary since otherwise the `ErrorBoundary` component would never render the
    // `Switch` component (and thus e.g. routing to the `OpenModelRoute` to load a new project)
    this._onHashChangeEvent = (evt: HashChangeEvent): void => {
      const s = evt.newURL.lastIndexOf("/");
      const newProjectID = evt.newURL.substr(s + 1);
      if (this.props.currentProjectID !== newProjectID) {
        this.setState({ resetErrorBoundary: true }, () => this.setState({ resetErrorBoundary: false }));
      }
    };
  }

  public componentDidMount(): void {
    window.addEventListener("hashchange", this._onHashChangeEvent);
  }

  public componentWillUnmount(): void {
    window.removeEventListener("hashchange", this._onHashChangeEvent);
  }

  public render() {
    return (
      <div className="ui">
        <React.Fragment>
          <SvgIconRegistry />
          <FullHeightContainer>
            <HashRouter>
              <ErrorBoundary reset={this.state.resetErrorBoundary}>
                <Switch>
                  <Route
                    exact={true}
                    path={AppRoutes.OpenTheModel + "/:projectID"}
                    component={OpenModelRoute}
                  />
                  <Route
                    exact={true}
                    path={AppRoutes.AlignTheModel + "/:projectID"}
                    render={this.renderAlignModel}
                  />
                  <Route
                    exact={true}
                    path={AppRoutes.RemeshTheModel + "/:projectID"}
                    render={this.renderRemeshModel}
                  />
                  <Route
                    exact={true}
                    path={AppRoutes.FlattenTheModel + "/:projectID"}
                    render={this.renderFlattenModel}
                  />
                  <Route
                    exact={false}
                    path="/:projectID?"
                    render={renderRedirect}
                  />
                  {/* Catch all URLs that didn't match any route */}
                  <Route render={renderNoMatch} />
                </Switch>
              </ErrorBoundary>
            </HashRouter>
          </FullHeightContainer>
          <ProgressDialog {...this.props.feedback} />
        </React.Fragment>
      </div>
    );
  }

  private renderAlignModel = (props: RouteComponentProps<{ projectID: string }>) => {
    const currentProjectID = this.props.currentProjectID;
    if (currentProjectID !== props.match.params.projectID) {
      this.props.resetInitialState();
      return renderRedirect(props);
    }
    this.props.enterAlignModelSection();
    return <AlignModelRoute {...props} />;
  };

  private renderRemeshModel = (props: RouteComponentProps<{ projectID: string }>) => {
    const currentProjectID = this.props.currentProjectID;
    if (currentProjectID !== props.match.params.projectID) {
      this.props.resetInitialState();
      return renderRedirect(props);
    }
    this.props.enterRemeshModelSection();
    return <RemeshModelRoute {...props} />;
  };

  private renderFlattenModel = (props: RouteComponentProps<{ projectID: string }>) => {
    const currentProjectID = this.props.currentProjectID;
    if (currentProjectID !== props.match.params.projectID) {
      this.props.resetInitialState();
      return renderRedirect(props);
    }
    this.props.enterFlattenModelSection();
    return <FlattenModelRoute {...props} />;
  };
}

declare const DEF_DEBUG_MODE: boolean;
function renderRedirect(props: RouteComponentProps<{ projectID?: string }>) {
  let projectID = props.match.params.projectID;
  if (DEF_DEBUG_MODE) {
    // type `localStorage.setItem("CADIUS_DEFAULT_LAST_PROJECT", "7ad9f34e-e048-11e9-82c2-47ee0fc17ed1")` in console
    const localID =
      localStorage.getItem("CADIUS_DEFAULT_LAST_PROJECT") || undefined;
    projectID = projectID || localID;
  }
  if (!projectID) {
    throw new Error("ASSERT: project not specified");
  }
  return <Redirect to={AppRoutes.OpenTheModel + "/" + projectID} />;
}

function renderNoMatch(props: RouteComponentProps) {
  return <NoMatch pathname={props.location.pathname} />;
}

function mapStateToProps(state: IApplicationState) {
  return {
    currentProjectID: state.remeshingProject?.id,
    feedback: state.ui.feedback,
  };
}

function mapDispatchToProps(dispatch: CadiusDispatch) {
  return {
    enterAlignModelSection: () => dispatch(enterAlignModelSection()),
    enterFlattenModelSection: () => dispatch(enterFlattenModelSection()),
    enterRemeshModelSection: () => dispatch(enterRemeshModelSection()),
    resetInitialState: () => dispatch(resetInitialState()),
  };
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

export const App = enhance(AppImpl);
