import { Render3DContext } from "cadius-cadlib";
import { CadiusSceneSource, IView, primitives } from "cadius-components";
import { ProjectionSurface, SplinePath } from "cadius-db";
import { firstOf } from "cadius-stdlib";
import { Line, Object3D, Vector3 } from "three";

import { flatteningPalette } from "../../palettes";
import { FlattenMode, IApplicationState } from "../interfaces";

export function updateView(state: IApplicationState): IView {
  const sources: CadiusSceneSource[] = [state.flattenModel.dashboard.graphics];

  if (state.flattenModel.interactorSources) {
    sources.push(state.flattenModel.interactorSources);
  }
  if (state.flattenModel.mode === FlattenMode.draw && state.flattenModel.curves) {
    sources.push(state.flattenModel.curves);
  }

  return {
    ...state.flattenModel.view,
    scene: state.flattenModel.view.scene.update(sources, state.ui.ctx),
  };
}

/**
 * @brief The name of the lines a flattened last is composed of.
 */
export type FlatCurvesNames =
  | "exterior"
  | "interior"
  | "front"
  | "back"
  | "cone";

export class GraphicalCurve {
  /**
   * @brief The current spline path of this line.
   */
  public spline: SplinePath;

  /**
   * @brief The rendered 3D object. Lazy evaluated.
   */
  private _graphics?: Line;

  private _renderOpts?: primitives.COptions;

  get renderOpts() {
    return this.renderOpts;
  }

  set renderOpts(opts: primitives.COptions) {
    this._renderOpts = opts;
    this._graphics = undefined;
  }

  constructor(spline: SplinePath, curveRenderOpts?: primitives.COptions) {
    this.spline = spline;
    this._renderOpts = curveRenderOpts;
  }

  get graphics(): Line {
    if (!this._graphics) {
      this._graphics = firstOf(primitives.renderCurve(this.spline.geodesic().points, this._renderOpts).objects) as Line;
    }
    return this._graphics;
  }

  /**
   * @brief Clones this curve and changes the projection surface and visibility according to the given parameter.
   *
   * @param [partial] an object that can contain a new projection surface, new control points, and visibility
   * attributes;
   * @returns a clone of this curve.
   */
  public clone(partial?: { projectionSurface?: ProjectionSurface, controlPoints?: Vector3[], visible?: boolean }): this {
    let newSpline = this.spline;
    if (partial && (partial.projectionSurface || partial.controlPoints)) {
      newSpline = newSpline.clone(partial);
    }

    const newGraphicalCurve = new GraphicalCurve(
      newSpline,
      this._renderOpts
    );

    if (partial &&
      this.graphics.visible !== partial.visible &&
      partial.visible !== undefined
    ) {
      newGraphicalCurve.graphics.visible = partial.visible;
    }

    return newGraphicalCurve as this;
  }
}

function createCOpts(curve: FlatCurvesNames): primitives.COptions {
  const color = flatteningPalette.get(curve);
  if (!color) {
    throw new Error(`ASSERT: unable to find color for curve ${curve}`);
  }
  return { params: { color, linewidth: 4 } };
};

export class FlattenCurves implements CadiusSceneSource {
  public exterior?: GraphicalCurve;
  public interior?: GraphicalCurve;
  public front?: GraphicalCurve;
  public back?: GraphicalCurve;
  public cone?: GraphicalCurve;

  constructor(
    coneSpline?: SplinePath,
    backSpline?: SplinePath,
    interiorSpline?: SplinePath,
    exteriorSpline?: SplinePath,
    frontSpline?: SplinePath
  ) {
    if (coneSpline) {
      this.cone = new GraphicalCurve(coneSpline, createCOpts("cone"));
    }
    if (backSpline) {
      this.back = new GraphicalCurve(backSpline, createCOpts("back"));
    }
    if (interiorSpline) {
      this.interior = new GraphicalCurve(interiorSpline, createCOpts("interior"));
    }
    if (exteriorSpline) {
      this.exterior = new GraphicalCurve(exteriorSpline, createCOpts("exterior"));
    }
    if (frontSpline) {
      this.front = new GraphicalCurve(frontSpline, createCOpts("front"));
    }
  }

  get graphicalCurves(): (GraphicalCurve | undefined)[] {
    return [this.cone, this.back, this.interior, this.exterior, this.front];
  }

  /**
   * @brief returns the splines this object contains.
   *
   * @readonly
   * @type {((SplinePath | undefined)[])}
   * @memberof FlattenCurves
   */
  get splines(): (SplinePath | undefined)[] {
    return this.graphicalCurves.map((s) => s ? s.spline : undefined);
  }

  public areEmpty(): boolean {
    return !(
      this.back ||
      this.cone ||
      this.exterior ||
      this.front ||
      this.interior
    );
  }

  public areFull(): boolean {
    return !!(
      this.back &&
      this.cone &&
      this.exterior &&
      this.front &&
      this.interior
    );
  }

  public changeEnvMap(): void { }

  public clone(projectionSurface?: ProjectionSurface, cloneControlPoints: boolean = false): FlattenCurves {
    const res = new FlattenCurves(
      this.cone ?
        this.cone.spline.clone({
          controlPoints: cloneControlPoints ? this.cone.spline.controlPoints.map((p) => p.clone()) : undefined,
          projectionSurface,
        }) : undefined,
      this.back ?
        this.back.spline.clone({
          controlPoints: cloneControlPoints ? this.back.spline.controlPoints.map((p) => p.clone()) : undefined,
          projectionSurface,
        }) : undefined,
      this.interior ?
        this.interior.spline.clone({
          controlPoints: cloneControlPoints ? this.interior.spline.controlPoints.map((p) => p.clone()) : undefined,
          projectionSurface,
        }) : undefined,
      this.exterior ?
        this.exterior.spline.clone({
          controlPoints: cloneControlPoints ? this.exterior.spline.controlPoints.map((p) => p.clone()) : undefined,
          projectionSurface,
        }) : undefined,
      this.front ?
        this.front.spline.clone({
          controlPoints: cloneControlPoints ? this.front.spline.controlPoints.map((p) => p.clone()) : undefined,
          projectionSurface,
        }) : undefined
    );
    if (this.cone && res.cone) {
      res.cone = res.cone.clone({ visible: this.cone.graphics.visible });
    }
    if (this.back && res.back) {
      res.back = res.back.clone({ visible: this.back.graphics.visible });
    }
    if (this.interior && res.interior) {
      res.interior = res.interior.clone({ visible: this.interior.graphics.visible });
    }
    if (this.exterior && res.exterior) {
      res.exterior = res.exterior.clone({ visible: this.exterior.graphics.visible });
    }
    if (this.front && res.front) {
      res.front = res.front.clone({ visible: this.front.graphics.visible });
    }
    return res;
  }

  public render3D(ctx: Render3DContext): Iterable<Object3D> {
    return this.graphicalCurves.filter((g) => g ? true : false).map((g) => g!.graphics);
  }

  /**
   * @brief Update the attribute `curveName`. This also update the changed curve dependencies
   *
   * @param curveName The curve to update;
   * @param spline The new value for the curve.
   * @returns a copy of this instance.
   */
  public updateCurve(curveName: FlatCurvesNames, spline: SplinePath): this {
    const curve = this[curveName];
    if (curve && curve.spline === spline) {
      return this;
    }

    // create a new instance without actually cloning every property.
    const renderOpts = createCOpts(curveName);

    const newCurves = Object.create(this);
    newCurves[curveName] = new GraphicalCurve(spline, renderOpts);

    return newCurves as this;
  }
};
