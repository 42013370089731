import {
  Box,
  Breadcrumb,
  BreadcrumbEntry,
  Grid,
  GridCell,
  Header,
  IconName,
  PrimaryButton,
  Sidebar,
  Stack,
  ToolButton,
} from "cadius-components";
import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Vector3 } from "three";

import { setBackendRemeshedLastURL } from "../actions/backend";
import { changeFlatteningMode, imposeFlatteningBoundaries } from "../actions/flatten-model";
import { CadiusDispatch } from "../actions/interfaces";
import { sceneSourceRefresh } from "../actions/render";
import { saveProjectOnBackend, uploadRemeshedLast } from "../actions/save-project";
import { hideGlobalFeedback, showGlobalAlert, showGlobalFeedback } from "../actions/ui";
import { FlatteningHelp } from "../components/Help";
import { FlatteningAlignmentPoints } from "../containers/FlatteningAlignmentPoints";
import { UploadFlatteningImage } from "../containers/UploadFlatteningImage";
import { FlatteningPointsInteractor } from "../interactors/flattening-point";
import { Phase } from "../phases";
import {
  FlattenMode,
  IApplicationState,
  IWorkingModel,
} from "../reducers/interfaces";
import { AppRoutes } from "../routes";
import { FlatteningLines } from "./FlatteningLines";
import { FlattenModel } from "./FlattenModel";

interface IProps extends RouteComponentProps<{ projectID: string }> {
  throat: Vector3 | undefined;
  tip: Vector3 | undefined;
  isRotating: boolean;
  mode: FlattenMode;
  canSave: boolean;
  theModel: IWorkingModel;
  calURL?: string;
  projectName?: string;
  onResetAlignmentPoints: () => void;
  onSave: () => void;
}

interface IFlattenRouteState {
  // When this is set, this component's render method performs a redirect to the aligning phase.
  redirectToAligning: boolean;
  // When this is set, this component's render method performs a redirect to the remeshing phase.
  redirectToRemeshing: boolean;
}

class FlattenModelRouteImpl extends React.Component<IProps, IFlattenRouteState> {
  constructor(props: IProps) {
    super(props);
    this.state = { redirectToAligning: false, redirectToRemeshing: false };
  }

  public render() {
    const { throat, tip, isRotating, mode, theModel } = this.props;
    if (!theModel.model) {
      return <Redirect to={AppRoutes.OpenTheModel + `/${this.props.match.params.projectID}`} />;
    }

    if (this.state.redirectToAligning) {
      return <Redirect to={AppRoutes.AlignTheModel + `/${this.props.match.params.projectID}`} />
    }

    if (this.state.redirectToRemeshing) {
      return <Redirect to={AppRoutes.RemeshTheModel + `/${this.props.match.params.projectID}`} />
    }

    return (
      <Grid
        templateAreas={[
          "header header",
          "breadcrumb sidebar",
          "content sidebar"
        ]}
        templateColumns={"8.5fr 1.5fr"}
        templateRows={"0.5fr 0fr 9.5fr"}
      >
        <GridCell
          gridArea={"header"}
          style={{ alignItems: "center", paddingLeft: "1rem" }}
        >
          <Header projectName={this.props.projectName} />
        </GridCell>
        <GridCell gridArea="breadcrumb">
          <Breadcrumb>
            <BreadcrumbEntry
              label={Phase.Alignment}
              selected={false}
              disabled={false}
              onClick={this.goToAligning}
            />
            <BreadcrumbEntry
              label={Phase.Remeshing}
              selected={false}
              disabled={false}
              onClick={this.goToRemeshing}
            />
            <BreadcrumbEntry
              label={Phase.Flattening}
              selected={true}
              disabled={false}
            />
          </Breadcrumb>
        </GridCell>
        <GridCell gridArea={"content"}>
          <FlattenModel />
        </GridCell>
        <GridCell gridArea="sidebar">
          <Sidebar>
            <UploadFlatteningImage />
            <div>
              {mode === FlattenMode.draw ? (
                <div className="box">
                  <div className="stack">
                    <ToolButton
                      iconName={IconName.Reset}
                      onClick={this.props.onResetAlignmentPoints}
                    >Reset Control Point</ToolButton>
                  </div>
                </div>) : null
              }
            </div>
            <Box>
              <Stack spacing="dense">
                {mode === FlattenMode.draw ? (
                  <Box>
                    <FlatteningLines />
                  </Box>
                ) : (
                    <FlatteningAlignmentPoints />
                  )}
              </Stack>
            </Box>
            {mode === FlattenMode.draw ? (
              <Box>
                <Grid templateAreas={["button url"]} templateColumns={"50% 50%"}>
                  <GridCell gridArea="button" style={{ alignItems: "center", paddingRight: "0.3rem" }}>
                    <PrimaryButton
                      disabled={!this.props.canSave}
                      onClick={this.props.onSave}
                    >
                      Save
                    </PrimaryButton>
                  </GridCell>
                  <GridCell gridArea="url" style={{ alignItems: "center", paddingLeft: "0.3rem" }}>
                    {this.props.calURL ? (
                      <a href={this.props.calURL}>
                        Download .cal
                      </a>
                    ) : <span>Save first!</span>}
                  </GridCell>
                </Grid>
              </Box>
            ) : <React.Fragment />}
            <FlatteningHelp mode={mode} throat={throat} tip={tip} isRotating={isRotating} />
          </Sidebar>
        </GridCell>
      </Grid>
    );
  }

  private goToAligning = () => {
    this.setState({ redirectToAligning: true });
  }

  private goToRemeshing = () => {
    this.setState({ redirectToRemeshing: true });
  }
}

function mapStateToProps(state: IApplicationState) {
  const { throat, tip } = state.flattenModel.alignmentPoints;
  const curveInteractor = state.interactorStack.interactors.find((i) => (
    i.kind === "EditCurve" || i.kind === "NewCurve"
  ));
  const flatteningInteractor = state.interactorStack.interactors.find((i) => i.kind === "FlatteningPointsInteractor");
  const projectName = state.remeshingProject?.name;
  return {
    calURL: state.remeshingProject && state.remeshingProject.cal_url,
    canSave: state.flattenModel.curves.areFull() && curveInteractor === undefined,
    isRotating: !!flatteningInteractor && (flatteningInteractor as FlatteningPointsInteractor).mode === "rotate",
    mode: state.flattenModel.mode,
    projectName,
    theModel: state.theModel,
    throat,
    tip,
  };
}

const mapDispatchToProps = (dispatch: CadiusDispatch) => {
  return {
    onResetAlignmentPoints: () => {
      dispatch(changeFlatteningMode(FlattenMode.align));
      dispatch(sceneSourceRefresh());
    },
    onSave: async () => {
      await dispatch(showGlobalFeedback("flattening foot-upper halves..."));
      dispatch(imposeFlatteningBoundaries()).then(async () => {
        dispatch(hideGlobalFeedback());
        await dispatch(showGlobalFeedback("saving..."));
        const url = await dispatch(uploadRemeshedLast());
        await dispatch(showGlobalFeedback("saved"));
        dispatch(setBackendRemeshedLastURL(url));
        dispatch(saveProjectOnBackend());
        setTimeout(() => dispatch(hideGlobalFeedback()), 3000);
      }).catch((reason) => {
        dispatch(showGlobalAlert(reason, "Error"));
      });
    },
  };
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const FlattenModelRoute = enhance(FlattenModelRouteImpl);
