import { EditCurveInteractor, IconName, IIconProps, IItemProps, Interactor } from "cadius-components";
import { SplinePath } from "cadius-db";
import { connect } from "react-redux";

import {
  checkAndSetFlatteningCurve,
  flatCurveNames,
  newFlatteningCurve,
  setFlatteningCurvesVisibility,
  startFlatteningCurveEdit,
} from "../actions/flatten-model";
import { resetInteractors } from "../actions/interactor";
import { CadiusDispatch } from "../actions/interfaces";
import { sceneSourceRefresh } from "../actions/render";
import { saveProjectOnBackend } from "../actions/save-project";
import { FlatteningLines as FlatteningLinesImpl } from "../components/FlatteningLines";
import { flatteningPalette } from "../palettes";
import { IApplicationState } from "../reducers/interfaces";
import { FlatCurvesNames } from "../reducers/support/flatten";

const ICONS = [{ name: IconName.New }, { name: IconName.Delete }];

interface IStateProps {
  bootstrap?: {
    back: SplinePath;
    cone: SplinePath;
    exterior: SplinePath;
    front: SplinePath;
    interior: SplinePath;
  };
  // The current interactor stack
  interactors: Interactor[];
  // a flag that indicates if an EditCurveInteractor is currently pushed on the stack.
  isEditing: boolean;
  // The list of items shown by this component.
  items: IItemProps[];
};

interface IDispatchProps {
  resetInteractors: (interactors: Interactor[]) => void;
  resetFlattenCurve: (curveName: FlatCurvesNames, curve: SplinePath) => Promise<void>;
  setFlatteningCurveVisibility: (curves: FlatCurvesNames[], visible: boolean) => void;
  sceneSourceRefresh: () => void;
  startDrawingFlattenCurve: (curveName: FlatCurvesNames) => void;
  startEditingFlattenCurve: (curveName: FlatCurvesNames) => void;
}

function mapStateToProps(state: IApplicationState): IStateProps {
  const stateProps: IStateProps = {
    interactors: state.interactorStack.interactors,
    isEditing: false,
    items: []
  };

  if (stateProps.interactors.length > 0) {
    stateProps.isEditing = stateProps.interactors[stateProps.interactors.length - 1].kind === "EditCurve";
  }

  const bootstrap = state.theModel.bootstrap;
  if (!bootstrap) {
    return stateProps;
  }

  stateProps.items = [
    { colorKey: "interior", itemId: "interior", label: "Interior", flatteningCurve: "interior" as FlatCurvesNames },
    { colorKey: "exterior", itemId: "exterior", label: "Exterior", flatteningCurve: "exterior" as FlatCurvesNames },
    { colorKey: "cone", itemId: "cone", label: "Cone", flatteningCurve: "cone" as FlatCurvesNames },
    { colorKey: "front", itemId: "front", label: "Front middle edge", flatteningCurve: "front" as FlatCurvesNames },
    { colorKey: "back", itemId: "back", label: "Back middle edge", flatteningCurve: "back" as FlatCurvesNames },
  ].map((e) => {
    const lastInteractor = state.interactorStack.interactors[state.interactorStack.interactors.length - 1];
    const curves = state.flattenModel.curves;
    const icons = ICONS.map((i): IIconProps => {
      switch (i.name) {
        case IconName.New:
          return {
            ...i,
            selected: stateProps.isEditing &&
              (lastInteractor as EditCurveInteractor).curveIdentifier === e.flatteningCurve
          };
        case IconName.Delete:
          return { ...i, disabled: false };
        default:
          return { ...i };
      };
    });

    return {
      iconColor: "#" + flatteningPalette.get(e.colorKey)!.getHexString(),
      icons,
      id: e.itemId,
      label: e.label,
      selected: (
        curves &&
        curves[e.flatteningCurve] &&
        !curves[e.flatteningCurve]!.graphics.visible &&
        stateProps.isEditing &&
        (lastInteractor as EditCurveInteractor).curveIdentifier === e.flatteningCurve
      ),
    };
  });

  stateProps.bootstrap = {
    back: bootstrap.back!.spline,
    cone: bootstrap.cone!.spline,
    exterior: bootstrap.exterior!.spline,
    front: bootstrap.front!.spline,
    interior: bootstrap.interior!.spline,
  };
  return stateProps;
}

function mapDispatchToProps(dispatch: CadiusDispatch): IDispatchProps {
  return {
    resetFlattenCurve: async (curveName: FlatCurvesNames, curve: SplinePath) => {
      await dispatch(checkAndSetFlatteningCurve(curveName, curve));
      dispatch(sceneSourceRefresh());
      dispatch(saveProjectOnBackend());
    },
    resetInteractors: (interactors: Interactor[]) => {
      dispatch(resetInteractors(interactors));
    },
    sceneSourceRefresh: () => {
      dispatch(sceneSourceRefresh());
    },
    setFlatteningCurveVisibility: (curves: FlatCurvesNames[], visible: boolean) => {
      dispatch(setFlatteningCurvesVisibility(curves, visible));
    },
    startDrawingFlattenCurve: (curveName: FlatCurvesNames) => {
      dispatch(newFlatteningCurve(curveName));
    },
    startEditingFlattenCurve: (curveName: FlatCurvesNames) => {
      dispatch(startFlatteningCurveEdit(curveName));
    }
  };
}

/*
 * The following allow us to adapt the `resetFlattenCurve` action creator to the `resetFlattenCurve` prop of the
 * wrapped component.
 */
function mergeProps<TOwnProps extends object>(
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: TOwnProps
) {

  const stopOtherCurveInteractor = () => {
    if (stateProps.isEditing) {
      let interactors = stateProps.interactors;
      const curveInteractorIdx = interactors.findIndex((i) => i.kind === "EditCurve");
      const stoppedCurveIdentifier = (interactors[curveInteractorIdx] as EditCurveInteractor)
        .curveIdentifier as FlatCurvesNames;
      interactors = stateProps.interactors.slice(0, curveInteractorIdx);
      dispatchProps.setFlatteningCurveVisibility(flatCurveNames, true);
      dispatchProps.resetInteractors(interactors);
      return stoppedCurveIdentifier;
    }
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    resetFlattenCurve: async (curveName: FlatCurvesNames) => {
      if (!stateProps.bootstrap) {
        throw new Error("ASSERT: no bootstrap curve set");
      }
      stopOtherCurveInteractor();
      const spline = stateProps.bootstrap[curveName];
      await dispatchProps.resetFlattenCurve(
        curveName,
        spline.clone({ controlPoints: spline.controlPoints.map((p) => p.clone()) })
      );
    },
    startDrawingFlattenCurve: (curveName: FlatCurvesNames) => {
      if (stopOtherCurveInteractor() !== curveName) {
        dispatchProps.startDrawingFlattenCurve(curveName);
      } else {
        dispatchProps.sceneSourceRefresh();
      }
    },
    startEditingFlattenCurve: (curveName: FlatCurvesNames) => {
      if (stopOtherCurveInteractor() !== curveName) {
        dispatchProps.startEditingFlattenCurve(curveName);
      } else {
        dispatchProps.sceneSourceRefresh();
      }
    },
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
);

export const FlatteningLines = enhance(FlatteningLinesImpl);
