import {
  applyMiddleware,
  CombinedState,
  createStore,
  Middleware,
  Store,
  StoreEnhancer,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import reduxThunk from "redux-thunk";

import { HANDLE_CAD_EVENT } from "./actions/events";
import { rootReducer } from "./reducers";
import { IApplicationState } from "./reducers/interfaces";

const getStoreEnhancer = (debug?: boolean, ...middlewares: Middleware<unknown, {}>[]) => {
  let storeEnhancer: StoreEnhancer;

  if (debug) {
    const logger = createLogger({
      collapsed: true,
      duration: true,
      level: "log",
      predicate: (getState, action) => action.type !== HANDLE_CAD_EVENT
    });
    applyMiddleware()
    storeEnhancer = composeWithDevTools(
      applyMiddleware(reduxThunk, logger, ...middlewares)
    );
  } else {
    storeEnhancer = composeWithDevTools(
      applyMiddleware(reduxThunk, ...middlewares)
    );
  }

  return storeEnhancer;
};

let _store: Store<IApplicationState> | undefined;

export function dispatch(action: any): void {
  _store!.dispatch(action);
}

export const initStore = (debug?: boolean, ...middlewares: Middleware<unknown, {}>[]) => {
  if (_store) {
    throw new Error("multiple store instances are not supported");
  }

  const storeEnhancer = getStoreEnhancer(debug, ...middlewares);

  _store = createStore(
    rootReducer,
    storeEnhancer
  );

  return _store;
};

export function resetStore() {
  _store = undefined;
};

export const getStore = (partialState: CombinedState<IApplicationState>, debug?: boolean, ...middlewares: Middleware<unknown, {}>[]) => {
  const storeEnhancer = getStoreEnhancer(debug, ...middlewares);
  return createStore(rootReducer, partialState, storeEnhancer);
};
