import {
  Box,
  Breadcrumb,
  BreadcrumbEntry,
  CurveSource,
  EditCurveInteractor,
  GraphicalEntities,
  Grid,
  GridCell,
  Header,
  IconName,
  IIconProps,
  IItemProps,
  Interactor,
  NewCurveInteractor,
  NumericInput,
  PointSource,
  PrimaryButton,
  Sidebar,
} from "cadius-components";
import {
  ConeAndFeatherPaths,
  LatitudeAndLongitudePaths,
  ProjectionSurface,
  SplinePath,
} from "cadius-db";
import { roundToDecimals } from "cadius-geo";
import { CppRemeshedLast } from "cadius-geo3d";
import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";

import { resetInteractors } from "../actions/interactor";
import { CadiusDispatch } from "../actions/interfaces";
import {
  CurveName,
  editFundamentalCurve,
  setCurveVisibility,
  setFundamentalCurve,
  setGridVisibility,
  setRemeshedLast,
  setRemeshingGrid,
  setTipThroatDistance,
  startDrawingFundamentalCurve as drawFundamentalCurve,
} from "../actions/remesh-model";
import { sceneSourceRefresh } from "../actions/render";
import { saveProjectOnBackend } from "../actions/save-project";
import { hideGlobalFeedback, showGlobalFeedback } from "../actions/ui";
import { remeshingPalette } from "../palettes";
import { Phase } from "../phases";
import { IApplicationState, IWorkingModel } from "../reducers/interfaces";
import { FlattenCurves } from "../reducers/support/flatten";
import { AppRoutes } from "../routes";
import { computeGrid, featherLengthPrecision } from "../support/remesh";
import { remeshAndFlattenLast } from "../support/remesh";
import { RemeshModel } from "./RemeshModel";

// The list of all icons shown for each entry of the curve widget. The rest of the properties (`enable` and `selected`)
// are updated with the state.
const ICONS = [{ name: IconName.New }, { name: IconName.Delete }];

interface IStateProps {
  // a flag indicating whether the remeshing functionality is available or not.
  canRemesh: boolean;
  // a flag indicating if the remeshing grid is currently shown.
  gridVisible: boolean;
  // The list of interactors currently in the stack.
  interactors: Interactor[];
  // a flag indicating whether there is an `EditCurveInteractor` on the stack;
  isEditing: boolean;
  // a flag indicating whether there is an `NewCurveInteractor` on the stack;
  isCreating: boolean;
  // the fundamental curves listed in the `GraphicalEntities` widget.
  items: IItemProps[];
  // the part of the application's state that contains the tip-to-throat distance and the bootstrap curves.
  theModel: IWorkingModel;
  // The canvas upon which the user can draw flattening lines
  flatteningCanvas: ProjectionSurface;
  // the remeshing grid for the foor-upper
  latitudeAndLongitudePaths?: LatitudeAndLongitudePaths;
  // the remeshing grid for the cone and feather.
  coneAndFeatherPaths?: ConeAndFeatherPaths;
  gridLines?: CurveSource[];
  coneEdge?: SplinePath;
  // the remesing project's name
  projectName?: string;
  featherEdge?: SplinePath;
  throat?: PointSource;
  tip?: PointSource;
}

interface IDispatchProps {
  hideGlobalFeedback: () => void;
  showGlobalFeedback: (info?: string, title?: string) => Promise<void>;
  deleteRemeshLine: (itemID: CurveName) => void;
  editFundamentalCurve: (curve: CurveName) => void;
  hideRemeshingGrid: () => void;
  resetInteractors: (interactors: Interactor[]) => void;
  sceneSourceRefresh: () => void;
  setCurveVisibility: (itemId: CurveName, visible: boolean) => void;
  setRemeshedLast: (remeshedLast: CppRemeshedLast, bootstrap: FlattenCurves, oldBootstrap?: FlattenCurves) => void;
  setRemeshingGrid: (
    coneAndFeatherPaths: ConeAndFeatherPaths,
    latitudeAndLongitudePaths: LatitudeAndLongitudePaths,
    gridLines: CurveSource[],
    throat: PointSource,
    tip: PointSource,
    featherLength: number
  ) => void;
  setTipThroatDistance: (tipThroatDistance: number) => void;
  startDrawingRemeshLine: (itemID: CurveName) => void;
}

interface IProps extends RouteComponentProps<{ projectID: string }>, IStateProps {
  // reset a remeshing curve to the bootstrap's value.
  deleteRemeshLine: (itemID: CurveName) => void;
  // starts the interactor to edit the given remeshing curve.
  editFundamentalCurve: (curve: CurveName) => void;
  // hide the remeshing grid.
  hideRemeshingGrid: () => void;
  // add the remeshing grid to the application state.
  setRemeshingGrid: () => void;
  // add the remeshed last to the application state
  setRemeshedLast: () => Promise<void>;
  // set the tip-to-throat distance (in mm).
  setTipThroatDistance: (tipThroatDistance: number) => void;
  // starts the interactor to draw a new fundamental curve.
  startDrawingRemeshLine: (itemID: CurveName) => void;
}

interface IRemeshRouteState {
  // When this is set, this component's render method performs a redirect to the next phase.
  redirectToFlattening: boolean;
  // When this is set, this component's render method performs a redirect to the previous phase.
  redirectToAligning: boolean;
}

class RemeshModelRouteImpl extends React.Component<IProps, IRemeshRouteState> {
  constructor(props: IProps) {
    super(props);
    this.state = { redirectToFlattening: false, redirectToAligning: false };
  }

  public render() {
    const { theModel } = this.props;

    if (!theModel.model) {
      return <Redirect to={AppRoutes.OpenTheModel + `/${this.props.match.params.projectID}`} />;
    }

    if (this.state.redirectToFlattening) {
      return <Redirect to={AppRoutes.FlattenTheModel + `/${this.props.match.params.projectID}`} />
    }

    if (this.state.redirectToAligning) {
      return <Redirect to={AppRoutes.AlignTheModel + `/${this.props.match.params.projectID}`} />
    }

    let maxLength = Number.MAX_VALUE;
    if (this.props.latitudeAndLongitudePaths) {
      maxLength = this.props.latitudeAndLongitudePaths.frontMiddleEdge.geometry().length();
    }

    return (
      <Grid
        templateAreas={[
          "header header",
          "breadcrumb sidebar",
          "view-3d sidebar"
        ]}
        templateColumns={"8.5fr 1.5fr"}
        templateRows={"0.5fr 0fr 9.5fr"}
      >
        <GridCell
          gridArea={"header"}
          style={{ alignItems: "center", paddingLeft: "1rem" }}
        >
          <Header projectName={this.props.projectName} />
        </GridCell>
        <GridCell gridArea="breadcrumb">
          <Breadcrumb>
            <BreadcrumbEntry
              label={Phase.Alignment}
              selected={false}
              disabled={false}
              onClick={this.goToAligning}
            />
            <BreadcrumbEntry
              label={Phase.Remeshing}
              selected={true}
              disabled={false}
            />
            <BreadcrumbEntry
              label={Phase.Flattening}
              selected={false}
              disabled={!this.props.gridVisible}
              onClick={this.remesh}
            />
          </ Breadcrumb>
        </GridCell>
        <GridCell gridArea={"view-3d"}>
          <RemeshModel />
        </GridCell>
        <GridCell gridArea={"sidebar"}>

          <Sidebar>
            <Box>
              <GraphicalEntities items={this.props.items} onClick={this.onClick} />
            </Box>
            <Box>
              <PrimaryButton
                disabled={!this.props.canRemesh || this.props.gridVisible}
                onClick={this.computeRemeshingGrid}>
                {"Make remeshing grid"}
              </PrimaryButton>
            </Box>
            <Box>
              <p>Feather Length: {Number(this.props.theModel.featherLength).toFixed(featherLengthPrecision)} mm</p>
              <NumericInput
                disabled={!this.props.gridVisible}
                label={"Tip-Throat Distance"}
                onChange={this.props.setTipThroatDistance}
                unit={"mm"}
                max={maxLength}
                min={0}
                initialValue={this.props.theModel.tipThroatDistance}
              />
            </Box>
          </Sidebar>
        </GridCell>
      </Grid>
    );
  }

  private goToAligning = () => {
    this.setState({ redirectToAligning: true });
  }

  private computeRemeshingGrid = () => {
    this.props.setRemeshingGrid();
  };

  // Compute the remeshed last and move on with the next phase.
  private remesh = async () => {
    await this.props.setRemeshedLast();
    this.setState({ redirectToFlattening: true });
  };

  // Dispatch a different action on the basis of the icon's type.
  private onClick = (curveName: string | number, icon?: IconName) => {
    this.props.hideRemeshingGrid();
    switch (icon) {
      case undefined: {
        this.props.editFundamentalCurve(curveName as CurveName);
        break;
      }
      case IconName.New: {
        this.props.startDrawingRemeshLine(curveName as CurveName);
        break;
      }
      case IconName.Delete: {
        this.props.deleteRemeshLine(curveName as CurveName);
        break;
      }
      default:
        throw new Error(
          `icon is ${icon}. There are no actions associated with this icon with this icon in the flatten section of the app.`
        );
    }
  };
}

function mapStateToProps(state: IApplicationState): IStateProps {
  const { remeshModel, theModel, flattenModel } = state;
  const flatteningCanvas = flattenModel.dashboard.canvas;
  const projectName = state.remeshingProject?.name;
  const canRemesh = (
    remeshModel.coneEdge &&
    remeshModel.featherEdge &&
    remeshModel.sources &&
    remeshModel.sources.coneEdge &&
    remeshModel.sources.featherEdge &&
    remeshModel.sources.featherEdge.visible &&
    remeshModel.sources.coneEdge.visible
  ) as boolean;

  const gridVisible = (
    remeshModel.sources &&
    remeshModel.sources.gridLines.length > 0 &&
    remeshModel.sources.gridLines[0].visible
  ) as boolean;

  const items: IItemProps[] = [];

  const interactors = state.interactorStack.interactors;
  let isEditing = false;
  let isCreating = false;

  if (interactors.length > 0) {

    const lastInteractor = interactors[interactors.length - 1];
    isEditing = lastInteractor.kind === "EditCurve";
    isCreating = lastInteractor.kind === "NewCurve";

    items.push(...[
      { colorKey: "feather", itemId: "featherEdge", label: "Feather Edge", remeshingCurve: "featherEdge" as CurveName },
      { colorKey: "cone", itemId: "coneEdge", label: "Cone Edge", remeshingCurve: "coneEdge" as CurveName }
    ].map((e) => {
      const unavailable = remeshModel[e.remeshingCurve] === undefined;

      const icons = ICONS.map((i): IIconProps => {
        switch (i.name) {
          case IconName.New:
            return {
              ...i,
              selected: isCreating &&
                (lastInteractor as NewCurveInteractor).curveIdentifier === e.remeshingCurve
            };
          case IconName.Delete:
            return { ...i, disabled: unavailable };
          default:
            return { ...i };
        };
      });

      return {
        iconColor: "#" + remeshingPalette.get(e.colorKey)!.getHexString(),
        icons,
        id: e.itemId,
        label: e.label,
        selected: (
          remeshModel.sources &&
          remeshModel.sources[e.remeshingCurve] &&
          !remeshModel.sources[e.remeshingCurve]!.visible &&
          isEditing &&
          (lastInteractor as EditCurveInteractor).curveIdentifier === e.remeshingCurve
        ),
        unavailable
      };
    }));
  }

  const { coneEdge, coneAndFeatherPaths, featherEdge, latitudeAndLongitudePaths, sources } = state.remeshModel;
  return {
    canRemesh,
    coneAndFeatherPaths,
    coneEdge,
    featherEdge,
    flatteningCanvas,
    gridLines: sources ? sources.gridLines : undefined,
    gridVisible,
    interactors,
    isCreating,
    isEditing,
    items,
    latitudeAndLongitudePaths,
    projectName,
    theModel,
    throat: sources ? sources.throat : undefined,
    tip: sources ? sources.tip : undefined,
  };
}

function mapDispatchToProps(dispatch: CadiusDispatch): IDispatchProps {
  const actionCreators = bindActionCreators({
    resetInteractors,
    sceneSourceRefresh,
    setCurveVisibility,
    setRemeshedLast,
  }, dispatch)
  return {
    ...actionCreators,
    deleteRemeshLine: (itemID: CurveName) => {
      dispatch(setFundamentalCurve(itemID));
      dispatch(sceneSourceRefresh());
      dispatch(saveProjectOnBackend());
    },
    editFundamentalCurve: (curve: CurveName) => {
      dispatch(setGridVisibility(false));
      // The following also triggers a scene refresh. No need to dispatch a refresh explicitly.
      dispatch(editFundamentalCurve(curve));
    },
    hideGlobalFeedback: () => {
      dispatch(hideGlobalFeedback());
    },
    hideRemeshingGrid: () => {
      dispatch(setGridVisibility(false));
      dispatch(sceneSourceRefresh());
    },
    setRemeshingGrid: (
      coneAndFeatherPaths: ConeAndFeatherPaths,
      latitudeAndLongitudePaths: LatitudeAndLongitudePaths,
      gridLines: CurveSource[],
      throat: PointSource,
      tip: PointSource,
      featherLength: number
    ) => {
      dispatch(setRemeshingGrid(coneAndFeatherPaths, latitudeAndLongitudePaths, gridLines, throat, tip, featherLength));
    },
    setTipThroatDistance: (tipThroatDistance: number) => {
      dispatch(setTipThroatDistance(tipThroatDistance));
      dispatch(saveProjectOnBackend());
    },
    showGlobalFeedback: (info?: string, title?: string): Promise<void> => {
      return dispatch(showGlobalFeedback(info, title));
    },
    startDrawingRemeshLine: (itemID: CurveName) => {
      dispatch(setGridVisibility(false));
      dispatch(drawFundamentalCurve(itemID));
      dispatch(sceneSourceRefresh());
    },
  };
}

function mergeProps<TOwnProps extends object>(
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: TOwnProps
) {
  /*
   * Checks if any other curve interactor is on top of the stack, and remove it by restoring the stack to the moment
   * before the other interactor was pushed.
   */
  const stopOtherCurveInteractor = (): string | undefined => {
    if (stateProps.isEditing || stateProps.isCreating) {
      const interactors = stateProps.interactors.slice();
      const stoppedCurveIdentifier = ((interactors.splice(-1, 1)[0] as EditCurveInteractor | NewCurveInteractor)
        .curveIdentifier as CurveName);
      dispatchProps.setCurveVisibility(stoppedCurveIdentifier, true);
      dispatchProps.resetInteractors(interactors);
      return stoppedCurveIdentifier;
    }
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    deleteRemeshLine: (itemID: CurveName) => {
      stopOtherCurveInteractor();
      dispatchProps.deleteRemeshLine(itemID);
    },
    editFundamentalCurve: (itemID: CurveName) => {
      const wasEditing = stateProps.isEditing;
      const stoppedInteractorId = stopOtherCurveInteractor();
      if (!wasEditing || stoppedInteractorId !== itemID) {
        dispatchProps.editFundamentalCurve(itemID);
      }
    },
    setRemeshedLast: async () => {
      if (!stateProps.theModel.remeshedLast) {
        await dispatchProps.showGlobalFeedback("remeshing...", "Compute");
        const { remeshedLast, bootstrap } = remeshAndFlattenLast(
          stateProps.flatteningCanvas,
          stateProps.coneAndFeatherPaths!,
          stateProps.latitudeAndLongitudePaths!,
          stateProps.theModel.tipThroatDistance
        );
        dispatchProps.setRemeshedLast(remeshedLast, bootstrap, stateProps.theModel.bootstrap);
        dispatchProps.hideGlobalFeedback();
      }
    },
    setRemeshingGrid: async () => {
      let { coneAndFeatherPaths, latitudeAndLongitudePaths, gridLines, throat, tip } = stateProps;
      let featherLength = stateProps.theModel.featherLength;
      if (coneAndFeatherPaths && latitudeAndLongitudePaths && gridLines && throat && tip) {
        dispatchProps.setRemeshingGrid(
          coneAndFeatherPaths,
          latitudeAndLongitudePaths,
          gridLines,
          throat,
          tip,
          featherLength
        );

        // tipThroatDistance may already have been changed by the user, in this case leave it unchanged
        // otherwise set it to 1/3 of the feather length (default)
        const oneThirdFeatherLength = roundToDecimals(stateProps.theModel.featherLength / 3, 2);
        if (stateProps.theModel.tipThroatDistance === oneThirdFeatherLength) {
          dispatchProps.setTipThroatDistance(roundToDecimals(featherLength / 3));
        }
      } else {
        await dispatchProps.showGlobalFeedback("computing remeshing grid...");
        try {
          const gridResult = computeGrid(
            stateProps.featherEdge!,
            stateProps.coneEdge!,
            stateProps.theModel.tipThroatDistance
          );
          coneAndFeatherPaths = gridResult.coneAndFeatherPaths;
          latitudeAndLongitudePaths = gridResult.latitudeAndLongitudePaths;
          gridLines = gridResult.gridLines;
          throat = gridResult.throat;
          tip = gridResult.tip;
          featherLength = gridResult.featherLength;

          dispatchProps.setRemeshingGrid(
            coneAndFeatherPaths,
            latitudeAndLongitudePaths,
            gridLines,
            throat,
            tip,
            featherLength
          );

          // tipThroatDistance may already have been changed by the user, in this case leave it unchanged
          // otherwise set it to 1/3 of the feather length (default)
          const oneThirdFeatherLength = roundToDecimals(stateProps.theModel.featherLength / 3, 2);
          if (stateProps.theModel.tipThroatDistance === oneThirdFeatherLength) {
            dispatchProps.setTipThroatDistance(roundToDecimals(featherLength / 3));
          }

          dispatchProps.hideGlobalFeedback();
        } catch (e) {
          await dispatchProps.showGlobalFeedback(
            "Remeshing grid uncorrect. Please check that the fundamental curves intersect the symmetry plane"
          );
          setTimeout(() => {
            dispatchProps.hideGlobalFeedback();
          }, 3000);
        }
      }
    },
    startDrawingRemeshLine: (itemID: CurveName) => {
      const wasCreating = stateProps.isCreating;
      const stoppedInteractorId = stopOtherCurveInteractor();
      if (!wasCreating || stoppedInteractorId !== itemID) {
        dispatchProps.startDrawingRemeshLine(itemID);
      };
    },
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
);

export const RemeshModelRoute = enhance(RemeshModelRouteImpl);
