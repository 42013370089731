import {
  AppControl,
  BaseInteractor,
  Cad,
  CadiusSceneSource,
  dimmer,
  lighter,
  PointEditorInteractor,
  primitives,
  ReactiveView,
  RENDER_ORDER,
} from "cadius-components";
import { ProjectionSurface } from "cadius-db";
import { L_EPS, L_EPS_SQ, signedAngle } from "cadius-geo";
import { firstOf } from "cadius-stdlib";
import { Line, LineDashedMaterial, Object3D, Vector3 } from "three";

import { FormsAppControl } from "../controls";
import { flatteningPointsPalette } from "../palettes";
import { IApplicationState } from "../reducers/interfaces";

/**
 * @brief The maximum distance in pixels to select a point.
 */
const MAX_DISTANCE_PX = 50;

/**
 * @brief Each point is rendered with the viewFinder primitive. This is the radius of the viewfinder.
 */
const HANDLER_RADIUS = 3;

/**
 * @brief An alias for the type stored by this interactor.
 */
export interface AlignmentFlatteningStoreType {
  points: [Vector3 | undefined, Vector3 | undefined];
  angle?: number;
}

/**
 * @brief This contains the three different looks for each alignment point.
 */
interface ViewfinderType {
  lighter: Object3D;
  normal: Object3D;
  dimmer: Object3D;
}

/**
 * Constants used by `FlatteningPointsInteractor` for various computations. Instanced once to reduce the garbage
 * collector's activity.
 */
// a vector representing the positive X-direction;
const _one = new Vector3(1, 0, 0);
// a vector representing the positive Z-direction.
const _dirZ = new Vector3(0, 0, 1);

/**
 * @brief This interactor allow the user to set both the throat and tip point for flattening.
 *
 * The `FlatteningPointInteractor` allow the user to select 2 points on the given projection surface. The points will be
 * stored in the flattening state as the _throat_ and _tip_ respectively. This interactor controls the point addition
 * process and once both points have been set, it does not allow setting new points anymore.
 *
 * This interactor can also edit existing points by starting the `PointEditorInteractor`. The user can select a point to
 * edit by clicking nearby and moving it to a new position.
 *
 * Once the two alignment points are set, the user can press `r` to enter in "rotate" mode. In this mode, the flattening
 * image is rotated around its _tip_ and the user is requested to align the image s.t. the beginning of the front middle
 * edge is horizontal. During "rotate" mode, no event is dispatched to any other interactor. The user can quit the
 * "rotate" mode and confirm the image orientation via left-click or by pressing `r` again.
 */
export class FlatteningPointsInteractor
  extends BaseInteractor<AlignmentFlatteningStoreType, IApplicationState>
  implements CadiusSceneSource {

  /**
   * @brief The surface where the points are projected onto.
   */
  private _projectionSurface: ProjectionSurface;

  /**
   * @brief The list of points set.
   */
  private _alingmentPoints: Vector3[];

  /**
   * @brief The index of the point currently selected for editing or covered by the pointer.
   */
  private _tmpIndex: number;

  /**
   * @brief The current mode of this interactor. When in "edit" mode a specialized interactor is pushed on the stack on
   * top of this. Otherwise, this is set to `"add"`;
   */
  private _mode: "add" | "edit" | "rotate";

  /**
   * @brief The point at the current mouse position.
   */
  private _tmpPoint: Vector3 | undefined;

  /**
   * @brief The set of objects rendered by this interactors.
   *
   * These include the points set so far, and the optional temporary object when the maximum number of points has not be
   * reached.
   */
  private _objects3D!: Object3D[];

  /**
   * @brief A line that connects the center of rotation to the current mouse position.
   *
   * This is show when this interactor is acting in "rotate" mode.
   */
  private _rotationRadius: Line;

  /**
   * @brief These are the dash size and gap size for the rotation radius 3D widget shown when "rotate" mode is active.
   */
  private _dashSize: number;
  private _gapSize: number;

  /**
   * @brief The following contains all the possible viewfinder's look available to this interactor. Each entry has 3
   * different options (_lighter_, _dimmer_, and _normal_). The first corresponds to the looks for the `throat` while
   * the latter are for the `tip`.
   */
  private _viewFinders: [ViewfinderType, ViewfinderType];

  /**
   * @brief Some temporary objects used by this interactor for various computations. They are instanced once to reduce
   * the garbage collector's aactivity.
   */
  // the direction that goes from the tip point to the current pointer location;
  private _dir1: Vector3;
  // the direction that goes from the tip point to the initial pointer location (when the interactor entered "rotate"
  // mode);
  private _dir2: Vector3;

  /**
   * @brief Construct this object.
   *
   * @param projectionSurface The surface where all the curves are drawn upon;
   * @param control The AppControl instance used to access the application state;
   * @param throat The existing throat point;
   * @param tip The existing tip point.
   */
  constructor(
    projectionSurface: ProjectionSurface,
    control: AppControl<AlignmentFlatteningStoreType, IApplicationState>,
    throat?: Vector3,
    tip?: Vector3
  ) {
    super(
      "FlatteningPointsInteractor",
      control
    );
    this._projectionSurface = projectionSurface;
    this._alingmentPoints = [];
    if (throat) {
      this._alingmentPoints.push(throat);

      if (tip) {
        this._alingmentPoints.push(tip);
      }
    }

    const zero = new Vector3();
    this._rotationRadius = firstOf(primitives.renderCurve([zero, _one.clone()], { style: "dashed" }).objects) as Line;
    this._rotationRadius.renderOrder = RENDER_ORDER.techLines;
    this._dashSize = (this._rotationRadius.material as LineDashedMaterial).dashSize;
    this._gapSize = (this._rotationRadius.material as LineDashedMaterial).gapSize;

    this._tmpIndex = -1;
    this._tmpPoint = undefined;
    this._dir1 = new Vector3();
    this._dir2 = new Vector3();
    this._mode = "add";
    const throatColor = flatteningPointsPalette.get("throat")!;
    const tipColor = flatteningPointsPalette.get("tip")!;

    this._viewFinders = [
      {
        dimmer: primitives.renderViewfinder(zero, HANDLER_RADIUS, dimmer(throatColor)),
        lighter: primitives.renderViewfinder(zero, HANDLER_RADIUS, lighter(throatColor)),
        normal: primitives.renderViewfinder(zero, HANDLER_RADIUS, throatColor),
      },
      {
        dimmer: primitives.renderViewfinder(zero, HANDLER_RADIUS, dimmer(tipColor)),
        lighter: primitives.renderViewfinder(zero, HANDLER_RADIUS, lighter(tipColor)),
        normal: primitives.renderViewfinder(zero, HANDLER_RADIUS, tipColor),
      },
    ];
    this.updateRenderedObjects();
  }

  get mode(): "add" | "edit" | "rotate" {
    return this._mode;
  }

  public changeEnvMap(): void { }

  /**
   * @brief Returns the list of 3D objects rendered by this interactor.
   */
  public render3D(): Iterable<Object3D> {
    return this._objects3D;
  }

  /**
   * Most of the following methods are defined just to intercept events when this interactor is in "rotate" mode.
   */
  protected onMousePressEvent(evt: Cad.MousePressEvent) {
    return this._mode === "rotate";
  }

  protected onMouseReleaseEvent(evt: Cad.MouseReleaseEvent) {
    return this._mode === "rotate";
  }

  protected onMiddleClickEvent(evt: Cad.MouseClickEvent) {
    return this._mode === "rotate";
  }

  protected onRightClickEvent(evt: Cad.MouseClickEvent) {
    return this._mode === "rotate";
  }

  protected onMouseDoubleClickEvent(evt: Cad.MouseDoubleClickEvent) {
    return this._mode === "rotate";
  }

  protected onMouseOutEvent(evt: Cad.MouseOutEvent) {
    return this._mode === "rotate";
  }

  protected onMouseOverEvent(evt: Cad.MouseOverEvent) {
    return this._mode === "rotate";
  }

  protected onMouseWheelEvent(evt: Cad.MouseWheelEvent) {
    return this._mode === "rotate";
  }

  /**
   * @brief Toggles the "rotate" mode for this interactor.
   *
   * @param evt The keydown event that triggered this method.
   */
  protected onKeyDownEvent(evt: Cad.KeyDownEvent) {
    if (evt.key !== "r" || !evt.hasNoModifier()) {
      return false;
    }

    if (this._mode !== "edit" && this._alingmentPoints.length >= 2) {
      if (this._mode === "add") {
        this._mode = "rotate";
        this._enterRotationMode(evt.view!.cadView);
      } else {
        this._mode = "add";
      }
      this.updateRenderedObjects();
      this._control.refresh();
    }
    return true;
  }

  protected onKeyUpEvent(evt: Cad.KeyUpEvent) {
    return this._mode === "rotate";
  }

  protected onDragLeaveEvent(evt: Cad.DragLeaveEvent) {
    return this._mode === "rotate";
  }

  /**
   * @brief Handles mouse motions.
   *
   * This updates the rendered temporary objects.
   *
   * @param evt The event to handle.
   * @returns true when the event has been handled by this interactor, false otherwise.
   */
  protected onMouseMotionEvent(evt: Cad.MouseMotionEvent): boolean {
    if (!evt.view) {
      return false;
    }
    if (this._mode !== "rotate" && (!evt.hasNoButton() || !evt.hasNoModifier())) {
      return false;
    }

    const point3D = this.getPoint3D(evt);
    if (!point3D) {
      return true;
    }

    if (this._mode === "rotate") {
      this._applyRotation(point3D);
    } else {
      this.updateRenderedObjects();
      this._control.refresh();
    }

    this._tmpIndex = this.findSelectedPointIdx(point3D, evt);
    this._tmpPoint = point3D;
    return true;
  }

  /**
   * @brief handle mouse clicks.
   *
   * This can trigger either the start of a new point creation or the editing of an existing one. It can also confirm
   * the changes when this interactor act in "rotate" mode.
   *
   * @param evt The event to handle
   * @returns true when the event has been handled by this interactor, false otherwise.
   */
  protected onLeftClickEvent(evt: Cad.MouseClickEvent): boolean {
    if (!evt.view) {
      return false;
    }

    if (!evt.hasNoModifier()) {
      return this._mode === "rotate";
    }

    if (this._mode === "rotate") {
      this._mode = "add";
      this.updateRenderedObjects();
      this._control.refresh();
      return true;
    }

    const point3D = this.getPoint3D(evt);
    if (!point3D) {
      return true;
    }

    if (this.editPoint(point3D, evt)) {
      return true;
    }

    if (this._alingmentPoints.length < 2) {
      this.addPoint(point3D);
    }
    return true;
  }

  /**
   * @brief Given the current pointer position, it updates `this._tmpIdx` with the index of the selected point or -1 if
   * no point is close enough to the pointer. The action radius is for the selection is bounded from below by `L_EPS` to
   * prevent the creation of points too close one from the other.
   *
   * @param point3D The pointer 3D position;
   * @param evt The mouse event that caused the handler to compute the new selected point.
   * @returns The index in `this._alingmentPoints` of the selected point or -1 if no point is selected.
   */
  private findSelectedPointIdx(point3D: Vector3, evt: Cad.MouseEvent): number {
    const actionRadius = Math.max(evt.view!.cadView.computeActionRadius(point3D, evt.position, MAX_DISTANCE_PX), L_EPS);
    const d = this._alingmentPoints.map((p, ix) => [p.distanceTo(point3D), ix])
      .sort((a, b) => a[0] - b[0]);

    if (d.length < 1) {
      return -1;
    }

    const best = d[0];
    return best[0] <= actionRadius ? best[1] : -1;
  }

  /**
   * @brief Unprojects  the current pointer position from screen-space to world-space.
   *
   * @param evt The mouse event whose screen position is used to compute the 3D point that lies on the projection
   * surface.
   * @returns the point on the projection surface that is currently under the pointer, or null if the mouse is not
   * above any point of the projection surface.
   */
  private getPoint3D(evt: Cad.MouseEvent): Vector3 | null {
    const ray = evt.view!.cadView.castRay(evt.position);
    return this._projectionSurface.geometry().intersectLoopPoint(ray);
  }

  /**
   * @brief Add a new control point with the given positon.
   *
   * @param point3D The position of the new control point.
   */
  private addPoint(point3D: Vector3): void {
    this._alingmentPoints.push(point3D);
    // _tmpIndex must be updated since we have just set a new point and the pointer is still overing on it.
    // Correct rendering depends on the _tmpIndex, so we need to keep it synchronized to the right value.
    this._tmpIndex = this._alingmentPoints.length - 1;

    this.updateRenderedObjects();
    this._control.store({ points: this._alingmentPoints as [Vector3, Vector3] });
  }

  /**
   * @brief Starts the editing process.
   *
   * Checks if the editing process can start (i.e. a point has been selected) and starts the `PointEditorInteractor`
   * interactor which actually performs the changes.
   *
   * @param point3D The 3D point where the user has clicked.
   * @param evt The mouse click that started the editing.
   */
  private editPoint(point3D: Vector3, evt: Cad.MouseEvent): boolean {
    this._tmpIndex = this.findSelectedPointIdx(point3D, evt);
    if (this._tmpIndex < 0) {
      return false;
    }

    this._mode = "edit";
    const color = flatteningPointsPalette.get(this._tmpIndex === 0 ? "throat" : "tip")!;
    this.sc.start(new PointEditorInteractor(this._projectionSurface, new FormsAppControl(
      (state: IApplicationState, newPoint: Vector3) => {
        this._alingmentPoints[this._tmpIndex] = newPoint;
        this._mode = "add";
        this.updateRenderedObjects();
        return this._control.store({ points: this._alingmentPoints as [Vector3, Vector3] });
      },
      () => { }),
      this._alingmentPoints[this._tmpIndex].clone(),
      lighter(color),
      (p: Vector3) => !this._alingmentPoints.some((ap) => ap.distanceToSquared(p) < L_EPS_SQ)
    ));

    this.updateRenderedObjects();
    return true;
  }

  /**
   * Starts the rotation action: to render a dashed line from the tip to the current mouse position, driving the user
   * to align the flattening image, the line must be positioned at the tip.
   * @private
   * @param {ReactiveView} cadView The view (and its camera) used for retrieving the 3D position under the mouse screen
   * pos.
   * @memberof FlatteningPointsInteractor
   */
  private _enterRotationMode(cadView: ReactiveView): void {
    this._rotationRadius.position.copy(this._alingmentPoints[1]);
  }

  /**
   * Each time the mouse moves, during rotation mode, the flattening image and the throat point must be rotated
   * accordingly.
   * @private
   * @param {Vector3} point3D Current 3D position corresponding to the mouse screen pos.
   * @memberof FlatteningPointsInteractor
   */
  private _applyRotation(point3D: Vector3): void {
    if (!this._tmpPoint) {
      this._tmpPoint = point3D;
    }
    this._dir1.subVectors(this._tmpPoint, this._alingmentPoints[1]);
    this._dir2.subVectors(point3D, this._alingmentPoints[1]);
    const a = signedAngle(this._dir1, this._dir2, _dirZ);

    this._alingmentPoints[0].sub(this._alingmentPoints[1]);
    this._alingmentPoints[0].applyAxisAngle(_dirZ, a);
    this._alingmentPoints[0].add(this._alingmentPoints[1]);

    this.updateRenderedObjects();

    this._control.store({ points: this._alingmentPoints as [Vector3, Vector3], angle: a });
  }

  /**
   * @brief Returns the list of rendering objects this interactor is responsable for.
   *
   *  This interactor can render 2 kind of points:
   * - Confirmed points: these are the points that have been set by the user. They are neither selected nor covered by
   *   the pointer;
   * - Temporary: this is either a preview of the point that would be created if the user clicks at the current pointer
   *   position or a different look for a confirmed point that would be edited if the user clicks.
   *
   * At any time there can any number of confirmed points, but only one temporary point. A temporary point can exist
   * during both of this interactor possible states  (`add` and `edit`) but the meaning for the temporary object
   * changes:
   *  - in `add` mode, the temporary object is the preview of the point to be added or an existing point currently below
   *    the pointer. The preview of a new point is not present in add mode if this interactor has already set 2 points.
   *
   *  - in `edit` mode, the temporary object is the original version of the existing point the user is currently
   *    editing.
   */
  private updateRenderedObjects() {
    this._objects3D = [];

    if (this._mode === "rotate" && this._tmpPoint) {
      this._dir1.subVectors(this._tmpPoint, this._alingmentPoints[1]);
      this._rotationRadius.quaternion.setFromAxisAngle(_dirZ, signedAngle(_one, this._dir1, _dirZ));
      const s = this._tmpPoint.distanceTo(this._alingmentPoints[1]);
      this._rotationRadius.scale.setScalar(s);
      (this._rotationRadius.material as LineDashedMaterial).dashSize = this._dashSize / s;
      (this._rotationRadius.material as LineDashedMaterial).gapSize = this._gapSize / s;
      (this._rotationRadius.material as LineDashedMaterial).needsUpdate = true;
      this._objects3D.push(this._rotationRadius);
    }

    // render all the confirmed points but the one that is in the nearby of the pointer.
    for (let i = 0; i < this._alingmentPoints.length; ++i) {
      const point = this._alingmentPoints[i];
      if (i !== this._tmpIndex || this._mode === "rotate") {
        this._viewFinders[i].normal.position.copy(point);
        this._objects3D.push(this._viewFinders[i].normal);
      }
    }

    // When the pointer is not over any existing point and there are less than 2 points set already, render the preview
    // for a point to be added.
    if (this._mode === "add" && this._alingmentPoints.length < 2 && this._tmpIndex < 0 && this._tmpPoint) {
      this._viewFinders[this._alingmentPoints.length].lighter.position.copy(this._tmpPoint);
      this._objects3D.push(this._viewFinders[this._alingmentPoints.length].lighter);
    } else if (this._tmpIndex >= 0) {
      // highlight the point below the cursor if we are not editing it yet
      if (this._mode === "add") {
        this._viewFinders[this._tmpIndex].lighter.position.copy(this._alingmentPoints[this._tmpIndex]);
        this._objects3D.push(this._viewFinders[this._tmpIndex].lighter);
      } else if (this._mode === "edit") {
        // dimmer the point below the cursor if we are already editing it.
        this._viewFinders[this._tmpIndex].dimmer.position.copy(this._alingmentPoints[this._tmpIndex]);
        this._objects3D.push(this._viewFinders[this._tmpIndex].dimmer);
      }
    }
  }
}
