import {
  Breadcrumb,
  BreadcrumbEntry,
  Grid,
  GridCell,
  Header,
  Sidebar,
} from "cadius-components";
import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { applyModelTransformation } from "../actions/align-model";
import { CadiusDispatch } from "../actions/interfaces";
import { AligningHelp } from "../components/Help";
import { Phase } from "../phases";
import { IApplicationState, ITransform, IWorkingModel } from "../reducers/interfaces";
import { isIdentity } from "../reducers/support/align";
import { AppRoutes } from "../routes";
import { AlignModel } from "./AlignModel";

interface IProps extends RouteComponentProps<{ projectID: string }> {
  // The current model. It contains the last to remesh.
  theModel: IWorkingModel;
  // The transformation specified by the user to align the last.
  localTransformation: ITransform;

  // Whether the remeshing grid is shown in the remesh model view or not. It is mandatory to inspect the remeshing grid
  // before moving to the flattening phase.
  gridVisible: boolean;

  // The remeshing project's name
  projectName?: string;

  // Applies the transformation to the last.
  applyTransformation: () => void;
}

interface IAlignRouteState {
  // When this is set, this component's render method performs a redirect to the remeshing phase.
  redirectToRemeshing: boolean;
  // When this is set, this component's render method performs a redirect to the flattening phase.
  redirectToFlattening: boolean;
}

class AlignModelRouteImpl extends React.Component<IProps, IAlignRouteState> {
  constructor(props: IProps) {
    super(props);
    this.state = { redirectToFlattening: false, redirectToRemeshing: false };
  }

  public render() {
    if (!this.props.theModel.model) {
      return <Redirect to={AppRoutes.OpenTheModel + `/${this.props.match.params.projectID}`} />;
    }

    if (this.state.redirectToRemeshing) {
      return <Redirect to={AppRoutes.RemeshTheModel + `/${this.props.match.params.projectID}`} />;
    }

    if (this.state.redirectToFlattening) {
      return <Redirect to={AppRoutes.FlattenTheModel + `/${this.props.match.params.projectID}`} />;
    }

    return (
      <Grid
        templateAreas={[
          "header header",
          "breadcrumb breadcrumb",
          "quadview sidebar"
        ]}
        templateColumns={"8.5fr 1.5fr"}
        templateRows={"0.5fr 0fr 9.5fr"}
      >
        <GridCell gridArea={"header"}>
          <Header projectName={this.props.projectName} />
        </GridCell>
        <GridCell gridArea="breadcrumb">
          <Breadcrumb>
            <BreadcrumbEntry
              label={Phase.Alignment}
              selected={true}
              disabled={false}
            />
            <BreadcrumbEntry
              label={Phase.Remeshing}
              selected={false}
              disabled={false}
              onClick={this.goToRemeshing}
            />
            <BreadcrumbEntry
              label={Phase.Flattening}
              selected={false}
              disabled={
                !this.props.gridVisible ||
                !isIdentity(
                  this.props.localTransformation.position,
                  this.props.localTransformation.rotation,
                  this.props.localTransformation.scale
                )
              }
              onClick={this.goToFlattening}
            />
          </ Breadcrumb>
        </GridCell>
        <GridCell gridArea={"quadview"}>
          <AlignModel />
        </GridCell>
        <Sidebar>
          <AligningHelp />
        </Sidebar>
      </Grid>
    );
  }

  // Apply the transformation and move on with the next phase
  private goToRemeshing = () => {
    this.setState({ redirectToRemeshing: true });
    this.props.applyTransformation();
  };

  private goToFlattening = () => {
    this.setState({ redirectToFlattening: true });
  }
}

function mapStateToProps(state: IApplicationState) {
  const { remeshModel, theModel } = state;
  const { localTransformation } = state.alignModel;
  const projectName = state.remeshingProject?.name;

  const gridVisible = remeshModel.sources !== undefined &&
    remeshModel.sources.gridLines.length > 0 &&
    remeshModel.sources.gridLines[0].visible

  return { gridVisible, localTransformation, projectName, theModel };
}

function mapDispatchToProps(dispatch: CadiusDispatch) {
  return {
    applyTransformation: () => {
      dispatch(applyModelTransformation());
    },
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const AlignModelRoute = enhance(AlignModelRouteImpl);
