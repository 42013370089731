import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { AlertDialog, FullHeightContainer } from "cadius-components";

import { CadiusDispatch } from "../actions/interfaces";
import { loadProject } from "../actions/load-project";
import { IApplicationState } from "../reducers/interfaces";
import { AppRoutes } from "../routes";
import { IProjectManager } from "cadius-db";

/**
 * The OpenModelRoute component needs the following props:
 * - a function to load the project with the given `props.match.params.projectID` (extracted from the path)
 * - an endpoint to route to, when the loading has completed (undefined if the loading has failed)
 * - a message to show in case of loading failure.
 * @interface IProps
 * @extends {RouteComponentProps<{ projectID: string }>}
 */
interface IProps extends RouteComponentProps<{ projectID: string }> {
  projectManager: IProjectManager;
  loadProject: (projectManager: IProjectManager, id: string) => Promise<void>;
  redirectTo?: AppRoutes;
  failureMessage: string;
}

interface IState {
  // when this is set, this component's render method performs a redirect to the next phase
  // and it happens right after the loading has completed
  canGoAhead: boolean;
  redirectTo?: AppRoutes;
  redirectToAfterConfirm?: string;
  currentProjectID: string;
}

class OpenModelRouteImpl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { canGoAhead: false, currentProjectID: props.match.params.projectID, redirectTo: props.redirectTo };
  }

  public async componentDidMount() {
    await this.props.loadProject(this.props.projectManager, this.props.match.params.projectID);
    this.setState({ canGoAhead: true });
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    if (
      this.state.canGoAhead &&
      !this.props.redirectTo &&
      !this.state.redirectToAfterConfirm &&
      this.props.match.params.projectID !== nextProps.match.params.projectID
    ) {
      this.setState({ canGoAhead: false }, this.componentDidMount);
    }
  }

  public render() {
    if (this.state.canGoAhead) {
      if (!this.props.redirectTo) {
        const redirectToAfterConfirm = this.state.redirectToAfterConfirm;
        if (redirectToAfterConfirm !== undefined) {
          return <Redirect to={redirectToAfterConfirm} />;
        }
        return (
          <FullHeightContainer>
            <AlertDialog
              type="error"
              title="Error"
              description={this.props.failureMessage}
              onConfirm={this.onConfirmFailureMessage}
            />
          </FullHeightContainer>
        );
      }
      // the model is ready, so we can go ahead
      return <Redirect to={this.props.redirectTo + `/${this.props.match.params.projectID}`} />;
    }
    // here the loading is ongoing, so we don't show anything in the meantime
    return <FullHeightContainer />;
  }

  private onConfirmFailureMessage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    // TODO: redirect to the dashboard (currently just go to the root)
    const redirectToAfterConfirm = "/";
    this.setState({ canGoAhead: true, redirectToAfterConfirm });
  };
}

function mapStateToProps(state: IApplicationState) {
  return {
    failureMessage: state.openModel.failureMessage,
    projectManager: state.projectManager,
    // redirect to:
    // 1. if even the bootstrap lines have been computed, route to the third phase
    // 2. if there are remeshing curves or the model transformation comes from the backend, route to the second phase
    // 3. otherwise, route to the first phase
    redirectTo: !state.theModel.model ?
      undefined : // before the loading or if it has failed to load
      state.theModel.bootstrap ?
        AppRoutes.FlattenTheModel :
        state.remeshModel.coneEdge || state.remeshModel.featherEdge || (
          state.remeshingProject && state.remeshingProject.original_model_transformation
        ) ?
          AppRoutes.RemeshTheModel :
          AppRoutes.AlignTheModel,
  };
}

function mapDispatchToProps(dispatch: CadiusDispatch) {
  return {
    loadProject: (projectManager: IProjectManager, id: string) => dispatch(loadProject(projectManager, id)),
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const OpenModelRoute = enhance(OpenModelRouteImpl);
